@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  /* COMMON CSS THROUGH OUT START ============================================================================================================================*/
  .cards-row-wrap {
    @apply flex flex-wrap -m-2;
  }
  .card-wrap {
    @apply p-2;
  }
  .card-box {
    @apply rounded-xl border border-utilityGray200 bg-white;
  }
  .card-box-md {
    @apply rounded-[10px] border border-utilityGray200 bg-white p-5;
  }
  .filter-modal-datepicker {
    .react-datepicker-popper {
      @apply w-full justify-center;
    }
  }
  .profile-icon-wrap {
    @apply relative flex-none;
  }
  .active-dot {
    @apply w-2.5 h-2.5 bg-successSecondary border border-white rounded-full;
  }
  .profile-icon-wrap .active-dot {
    @apply absolute right-0 bottom-0;
  }
  .dropdown-within-input .wal-hq-select .select__control {
    @apply rounded-tl-none rounded-bl-none border-t-0 border-b-0 border-r-0 lg:min-h-[34px] min-h-[32px];
  }
  /* COMMON CSS THROUGH OUT END============================================================================================================================*/

  /* COMMON NOTIFICCATION START ============================================================================================================================*/
  .notification-row {
    @apply !bg-utilityGray100 hover:!bg-utilityGray200;
  }
  .notification-mark-wrap {
    @apply relative;
  }
  .notification-dot {
    @apply border-[1.5px] border-white bg-primary500 rounded-full w-2.5 h-2.5 absolute top-0 left-0;
  }
  /* COMMON NOTIFICCATION END ============================================================================================================================*/

  /* COMMON TABLE START ============================================================================================================================*/
  .table-wrap-main {
    @apply rounded-xl border border-utilityGray200 bg-white shadow-sm flex flex-col;
  }
  .table-header {
    @apply w-full py-3 px-5 flex flex-wrap justify-between items-center gap-3 border-b border-utilityGray200;
  }
  .table-header-left {
    @apply flex items-center xxl:gap-4 gap-3;
  }
  .table-wrap {
    @apply overflow-x-auto scrollbar-hide flex-grow text-nowrap;
  }
  .table-wrap tr {
    @apply border-b border-utilityGray200 text-xs text-grayLight600 font-normal;
  }
  .table-wrap thead {
    @apply bg-gray50 border-t border-utilityGray200;
  }
  .table-wrap th {
    @apply font-medium px-5 py-3;
  }
  .table-wrap td {
    @apply px-5 py-4;
  }
  .table-wrap .table-img-icn {
    @apply w-8 h-8 rounded-full flex-none;
  }
  .table-wrap .table-img-icn img {
    @apply w-full h-full rounded-full border-[0.5px] border-utilityBlack/[0.08];
  }
  .table-profile-wrap {
    @apply flex-none;
  }
  .table-profile-icn-circle-sm {
    @apply w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none;
  }
  .table-profile-user-circle-sm {
    @apply w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px];
  }
  .table-profile-customer-circle-sm {
    @apply w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px];
  }
  .table-profile-empty-circle-sm {
    @apply w-5 h-5 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-utilityGray100 text-white;
  }
  .table-profile-icn-circle-xl {
    @apply w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none;
  }
  .table-profile-user-circle-xl {
    @apply w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-sm;
  }
  .table-profile-customer-circle-xl {
    @apply w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm;
  }
  .table-profile-empty-circle-xl {
    @apply w-8 h-8 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-utilityGray100 text-white;
  }
  .table-recordsPerPage {
    @apply min-w-[70px];
  }
  .table-selectbox {
    @apply xxl:w-[200px] xl:w-[190px] sm:w-44 smd:w-36 smd:flex-none  flex-1;
  }
  .table-statustbox {
    @apply w-[150px] smd:flex-none flex-1;
  }
  .table-searchInput {
    @apply xxl:w-[25rem] xlm:w-[19rem] sm:w-56 w-full;
  }
  .table-top-header {
    @apply px-5 py-3 flex sm:flex-nowrap flex-wrap items-center sm:gap-3 gap-2 border-b border-utilityGray200 justify-between;
  }
  .table-right-wrap {
    @apply flex flex-wrap gap-3;
  }
  .table-title {
    @apply text-grayLight900 text-base font-semibold;
  }
  .table-subtitle {
    @apply text-grayLight600 text-xs font-normal mt-1;
  }
  .table-left-wrap {
    @apply sm:flex-1;
  }
  .table-title-wrap {
    @apply flex items-center gap-1.5;
  }
  .table-bottom-header {
    @apply px-5 py-3 flex gap-3 flex-wrap;
  }
  .table-header-bottom-left {
    @apply smd:flex-1 flex-[0_0_100%];
  }
  .table-tab-wrap .table-header-bottom-left {
    @apply xls:flex-1 flex-none w-full;
  }
  .table-filter-btn {
    @apply smd:flex-none flex-1;
  }
  .filter-btn-selected {
    @apply rounded-full border border-borderSecondary text-primary700 w-4 h-4 text-[11px] flex items-center justify-center;
  }
  .table-tab {
    @apply sm:flex-none flex-1 xl:!px-4 lg:!px-3 sm:!px-3 xs:!px-0.5 sm:!text-xs !text-[11px];
  }
  .table-header-bottom-right {
    @apply flex gap-3 flex-wrap;
  }
  .table-tab-wrap .table-searchInput {
    @apply xls:flex-none smd:flex-1;
  }
  .table-tabs {
    @apply sm:!w-fit !w-full sm:!overflow-x-hidden !overflow-x-auto sm:text-wrap text-nowrap;
  }
  .table-datepicker-v2 {
    @apply lg:w-[240px] smd:w-[228px] w-full;
  }
  .table-without-title-header thead {
    @apply bg-transparent;
  }
  .table-without-title-header thead th {
    @apply bg-gray50;
  }
  .table-without-title-header thead th:first-child {
    @apply rounded-tl-xl;
  }
  .table-without-title-header thead th:last-child {
    @apply rounded-tr-xl;
  }

  .table-without-pagination tbody tr:last-child {
    border-bottom: 0;
  }
  .table-without-pagination tbody tr:last-child td:first-child {
    @apply rounded-bl-xl;
  }
  .table-without-pagination tbody tr:last-child td:last-child {
    @apply rounded-br-xl;
  }

  .table-without-pagination-tfoot tfoot tr:last-child {
    border-bottom: 0;
  }
  .table-without-pagination-tfoot tfoot tr:last-child td:first-child {
    @apply rounded-bl-xl;
  }
  .table-without-pagination-tfoot tfoot tr:last-child td:last-child {
    @apply rounded-br-xl;
  }

  /* COMMON TABLE END ============================================================================================================================*/

  .wal__sidebar_link {
    @apply text-gray500 w-full flex items-center py-1.5 px-2.5 text-xs  hover:text-grayLight900 relative cursor-pointer;
  }

  .wal__sidebar_sub_link {
    @apply text-gray500 w-full flex items-center py-2 px-2 text-xs  hover:text-grayLight900 relative cursor-pointer;
  }

  .active-nav {
    @apply text-grayLight900 bg-utilityGray400 rounded-md;
  }

  .link_icon {
    @apply w-5 h-5 text-gray500 transition duration-75;
  }

  .active_icon {
    @apply text-grayLight900;
  }

  .wal_smartq_tab {
    @apply inline-block w-full p-4 border-b-2 border-transparent transition-all ease-linear duration-100 rounded-t-lg hover:text-primary hover:border-primary;
  }

  .wal_smartq_tab-active {
    @apply text-primary border-primary;
  }
  .search-input {
    @apply shadow-sm focus:!shadow-[0_1px_2px_0px_rgba(16,24,40,0.05),0_0px_0px_4px_rgba(224,234,255,1)] border border-borderPrimary focus:border-utilityBrand200 transition-all duration-[0.3s] outline-0;
  }
  .form_control {
    @apply placeholder:text-gray-500 font-medium text-grayLight900 border focus:shadow border-borderPrimary rounded-lg text-xs px-3 lg:py-[9px] py-2 bg-white w-full lg:h-[36px] h-[34px] hover:border-grayText outline-0  focus:ring-0 focus:ring-transparent;
  }

  .form_label {
    @apply text-textSecondary text-xs md:mb-2 mb-1 font-medium leading-tight inline-block;
  }

  .form_label_center {
    @apply md:h-[36px] flex items-center;
  }

  .step_completed {
    @apply text-primary after:content-[''] after:w-full after:border-b after:border-primary after:border-2 after:inline-block;
  }

  .step_inprogress {
    @apply text-primary after:content-[''] after:w-full after:border-b after:border-2 after:inline-block;
  }

  .step_normal {
    @apply text-grayText after:content-[''] after:w-full after:border-b after:border-gray-200 after:border-2 after:inline-block;
  }

  .stepbar {
    @apply flex w-full items-center relative;
  }

  .step_last {
    @apply text-grayText w-auto;
  }

  .btn_primary {
    @apply bg-primary px-3 py-2 rounded-lg border border-primary font-semibold text-white hover:bg-primary700 transition-all delay-100;
  }

  .btn_secondary_black {
    @apply bg-white md:px-3 px-2 lg:py-[9px] py-2 rounded-lg border border-borderPrimary font-semibold text-textSecondary hover:bg-gray50 hover:border-borderPrimary transition-all delay-100;
  }
  .btn_secondary_solid {
    @apply rounded-lg !bg-utilityGray100 border-utilityGray100 hover:border-borderPrimary;
  }

  .btn_danger {
    @apply bg-red-600 px-3 py-2 rounded-lg border border-red-600 font-semibold text-white hover:bg-red-700 transition-all delay-100;
  }

  .btn_outline_primary {
    @apply outline-1 outline-primary outline bg-white transition-all delay-100 px-3 py-3 rounded-full text-sm font-semibold text-primary hover:bg-primary hover:text-white;
  }

  .row_active {
    @apply bg-grayLight;
  }

  input[id^='react-select-'] {
    @apply focus:outline-none focus:border-transparent focus:ring-0;
  }

  input[type='checkbox'] {
    @apply bg-white ring-0 focus:ring-0 focus:ring-transparent;
  }

  input[type='checkbox']:checked {
    @apply bg-white ring-0 focus:ring-0 focus:ring-transparent;
  }

  .rflb_text_title {
    @apply text-sm font-semibold text-grayText;
  }

  .tab_Rfqlb > button.active {
    @apply border bg-white text-primary;
  }

  .tab_Rfqlb > button {
    min-width: 200px;
    @apply text-black text-sm focus:ring-0 focus:ring-transparent font-bold;
  }
  .tabs-bordered > .tab {
    /* border-color: #D0D5DD;   */
    border: none;
  }
  .tab:is(input:checked) {
    border-bottom: 1px solid #444ce7;
    color: #444ce7;
    --tw-border-opacity: 1;
    --tw-text-opacity: 1;
  }
  .tabs-bordered > .tab-custom.tab:checked {
    @apply border-b-2 border-primary text-primary700;
  }
  .tabs-bordered > .tab-custom.tab {
    @apply h-auto max-h-fit text-sm  px-3 pb-3 font-semibold text-gray500 border-0 border-b border-solid border-utilityGray200;
  }
  .btn-outline-primary {
    @apply rounded-lg border border-borderSecondary bg-white shadow-xs text-primary700 !font-semibold hover:bg-primary50 hover:border-borderSecondary hover:text-primary700;
  }
  .copy-msg h2 {
    @apply !text-white;
  }
  .copy-msg .Toastify__close-button--light {
    @apply !text-white !self-auto !opacity-100;
  }
  .copy-msg.Toastify__toast {
    @apply !rounded-lg;
  }
  .Toastify__toast-container.Toastify__toast-container--top-center
    .Toastify__toast {
    @apply !left-2/4 !-translate-y-2/4;
  }
  .progress-success::-webkit-progress-value {
    @apply bg-fgSuccessPrimary;
  }
  .progress-danger::-webkit-progress-value {
    @apply bg-fgErrorPrimary;
  }
  .progress-yellow::-webkit-progress-value {
    @apply bg-yellow300;
  }
  .custom-reply-select .custom-reply-select__control {
    @apply border border-borderPrimary rounded-lg;
  }
  .custom-reply-select .custom-reply-select__value-container {
    @apply p-1 lg:pr-32 sm:pr-28 pr-24;
  }
  .custom-reply-select .custom-reply-select__indicators {
    @apply hidden;
  }
  .custom-reply-select .custom-reply-select__multi-value {
    @apply rounded-md bg-utilityGray100 py-1 px-2;
  }
  .custom-reply-select .custom-reply-select__multi-value__label {
    @apply p-0 text-xs mr-1 text-grayLight600 lowercase;
  }
  .custom-reply-select .custom-reply-select__multi-value__remove {
    @apply text-gray400;
  }
  .custom-reply-select .custom-reply-select__control {
    @apply text-xs;
  }
  .reply-modal-ck-editor .ck.ck-editor {
    @apply !h-full !flex !flex-col;
  }
  .reply-modal-ck-editor .ck.ck-editor__main {
    @apply flex-1;
  }
  .reply-modal-ck-editor .ck-content {
    @apply h-full;
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --tw-prose-pre-bg: #1f2937;
    --tw-prose-pre-code: #e5e7eb;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    /* --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%; */
    /*--radius: 0.5rem; */

    --novel-highlight-default: #ffffff;
    --novel-highlight-purple: #f6f3f8;
    --novel-highlight-red: #fdebeb;
    --novel-highlight-yellow: #fbf4a2;
    --novel-highlight-blue: #c1ecf9;
    --novel-highlight-green: #acf79f;
    --novel-highlight-orange: #faebdd;
    --novel-highlight-pink: #faf1f5;
    --novel-highlight-gray: #f1f1ef;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;

    --novel-highlight-default: #000000;
    --novel-highlight-purple: #3f2c4b;
    --novel-highlight-red: #5c1a1a;
    --novel-highlight-yellow: #5c4b1a;
    --novel-highlight-blue: #1a3d5c;
    --novel-highlight-green: #1a5c20;
    --novel-highlight-orange: #5c3a1a;
    --novel-highlight-pink: #5c1a3a;
    --novel-highlight-gray: #3a3a3a;
  }
}

.form_height {
  @apply h-[100%] relative max-w-[320px] md:min-w-[320px] mx-auto justify-center items-center flex;
}
